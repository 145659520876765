<template>
  <div class="coupon">
    <div
      v-show="!couponsApplied && !showCouponForm"
      class="coupon__trigger"
      @click="showCouponForm = !showCouponForm"
    >
      <Icon name="material-symbols:add" />
      {{ $t('cart.components.coupon_form.coupon_code') }}
    </div>

    <div
      v-if="!couponsApplied && showCouponForm"
      class="coupon__form"
    >
      <input
        v-model="couponCode"
        :placeholder="$t('cart.components.coupon_form.coupon_code')"
        type="text"
        @keyup.enter="applyCoupon"
      >
      <div
        :class="{
          'button--loading': isLoading,
          'button--disabled': isLoading
        }"
        class="button button--small"
        @click="applyCoupon"
      >
        {{ $t('cart.components.form.buttons.apply_coupon') }}
      </div>
      <span
        class="coupon__cancel"
        @click="showCouponForm = !showCouponForm"
      >
        {{ $t('buttons.cancel') }}
      </span>
    </div>

    <div
      v-if="couponsApplied"
      class="coupon__codes"
    >
      <div
        v-for="coupon in cart.applied_coupons"
        :key="coupon.code"
        class="coupon__code"
      >
        {{ $t('cart.components.coupon_form.applied_coupon') }}: <span>{{ coupon.code }}</span>
        <Icon
          class="coupon__remove"
          name="material-symbols:close"
          @click="removeCoupon"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
  import { computed } from "vue";
  import { useRecaptcha } from "~/composables/useRecaptcha";
  import applyCouponToCart from "../../apollo/queries/applyCouponToCart.gql";
  import removeCouponFromCart from "../../apollo/queries/removeCouponFromCart.gql";

  const cart = useCart();
  const { t } = useI18n();
  const { $toast } = useNuxtApp();
  const { useRecaptchaMutation } = useRecaptcha();
  const couponCode = useState('couponCode', () => '');
  const isLoading = useState('isLoading', () => false);
  const showCouponForm = useState('showCouponForm', () => false);
  const couponsApplied = computed(() => cart.value?.applied_coupons);

  async function applyCoupon() {
    const applyCouponInput = {
      input: {
        cart_id: cart.value.id,
        coupon_code: couponCode.value
      }
    }

    const { mutate, loading } = await useRecaptchaMutation(applyCouponToCart, applyCouponInput);
    isLoading.value = loading;
    const applyCouponResult = await mutate();

    if (applyCouponResult.data) {
      cart.value = applyCouponResult.data.applyCouponToCart.cart;
      $toast.success(t('cart.components.coupon_form.toast_coupon_code_applied', { coupon_code: couponCode.value }));
    }
  }

  async function removeCoupon() {
    const removeCouponInput = {
      input: {
        cart_id: cart.value.id
      }
    }
    const { mutate, loading } = await useRecaptchaMutation(removeCouponFromCart, removeCouponInput);
    isLoading.value = loading;
    const removeCouponResult = await mutate();

    if (removeCouponResult.data) {
      cart.value = removeCouponResult.data.removeCouponFromCart.cart;
      $toast.success(t('cart.components.coupon_form.toast_coupon_code_removed', { coupon_code: couponCode.value }));
      showCouponForm.value = false;
      couponCode.value = '';
    }
  }
</script>

<style lang="scss">
  .coupon {
    font-size: 14px;
    margin-top: 20px;
    font-weight: normal;
    color: var(--dark-grey, $dark-grey);

    &__trigger {
      cursor: pointer;
    }

    &__form {
      width: 100%;
      gap: 15px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;

      @include min-screen('medium') {
        display: flex;
      }

      .button {
        white-space: nowrap;
        padding: calc(var(--button-padding-vertical, 10px) * 0.6) 30px;
      }

      input[type="text"] {
        grid-column-end: span 2;
        padding: calc(var(--input-padding-vertical, 10px) * 0.75) calc(var(--input-padding-horizontal, 15px) * 0.75);
      }
    }

    &__code {
      gap: 5px;
      display: flex;
      align-items: center;
      line-height: 1;

      span {
        color: var(--black, $black);
        font-size: 14px;
      }
    }

    &__remove {
      cursor: pointer;
      font-size: 14px;
      margin-left: 3px;
      color: var(--error, $error);
      transform: translateY(-2px);
    }

    &__cancel {
      cursor: pointer;
      margin: 0 0 0 auto;
    }
  }
</style>
